import Button from 'src/components/elements/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { CHECK_VALID_LICENSE } from 'src/graphql/query'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from 'src/hooks/useLocalStorage'
import ROUTES_NAME from 'src/router/routesNames'
import { CREATE_ANONYMOUS_USER } from 'src/graphql/mutation'
import { useDispatchSteps } from 'src/context/steps-context'
import { filterArrayValues } from 'src/utils'
import styles from './AddLicense.module.scss'
import ExtraLicense from './ExtraLicense'
import AddLicenceFormInner from './AddLicenceFormInner'

const AddLicense = () => {
	const methods = useForm()
	const { handleSubmit } = methods
	const { setValue } = useLocalStorage('licenses', [])
	const { dispatch } = useDispatchSteps()
	const navigate = useNavigate()

	const [mainError, setMainError] = useState(null)
	const [error, setError] = useState([])
	const [buttonError, setButtonError] = useState('')

	const [checkLicense, { loading }] = useLazyQuery(CHECK_VALID_LICENSE, {
		onCompleted: (vals) => {
			const allOk = vals.licenceIsValid.every((item) => item.isValid)
			if (allOk) {
				const filtered = vals.licenceIsValid.map((item) => item.code)
				setValue(filtered)
				navigate(ROUTES_NAME.STEP1)
			}
			if (!allOk) {
				if (!vals.licenceIsValid[0].isValid) {
					setMainError({ message: vals.licenceIsValid[0].error })
				} else {
					setMainError(null)
				}
				const arr = vals.licenceIsValid.slice(1)
				const err = arr.map((item) => (!item.isValid ? { message: item.error } : null))
				setError(err)
			}
		}
	})

	const [createAnonUser, { loading: load }] = useMutation(CREATE_ANONYMOUS_USER, {
		onCompleted: (vals) => {
			if (!vals.createAnonymousUser.ok) {
				if (!vals.createAnonymousUser.licenceIsValid[0].isValid) {
					setMainError({ message: vals.createAnonymousUser.licenceIsValid[0].error })
				} else {
					setMainError(null)
				}
				const arr = vals.createAnonymousUser.licenceIsValid.slice(1)
				const err = arr.map((item) => (!item.isValid ? { message: item.error } : null))
				setError(err)
			} else {
				dispatch({
					type: 'SET_STEPS',
					payload: {
						steps: 4
					}
				})
				navigate(ROUTES_NAME.PAYMENT_STEP)
			}
		}
	})

	const onSubmit = handleSubmit((values) => {
		const formData = filterArrayValues(values)
		const correctData = formData.filter((item) => item).length === 0 ? [] : formData
		if (values.counter === 0 && correctData.length === 0) {
			setButtonError('Please choose quantity of children or type licence code')
		}
		if (values.counter > 0) {
			setButtonError('')
			createAnonUser({
				variables: { codes: correctData, extraLicenceQty: values.counter }
			})
		} else if (correctData.length) {
			setButtonError('')
			checkLicense({
				variables: { codes: correctData }
			})
		}
	})

	const isLoading = loading || load

	return (
		<FormProvider {...methods}>
			<form className={styles.license} onSubmit={onSubmit}>
				<AddLicenceFormInner
					mainError={mainError}
					error={error}
					setError={setError}
					required={false}
				/>
				<ExtraLicense />
				{buttonError && <p className="formControl__error">{buttonError}</p>}
				<Button
					color="primary"
					block
					type="submit"
					isLoading={isLoading}
					classname={styles.button}
				>
					Get started
				</Button>
			</form>
		</FormProvider>
	)
}

export default AddLicense
