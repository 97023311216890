import AddLicense from 'src/components/elements/AddLicense'
import BlockTitle from 'src/components/elements/BlockTitle'

import styles from './GetStarted.module.scss'

const GetStarted = () => {
	return (
		<div className={styles.block}>
			<div className="container">
				<div className={styles.desktop}>
					<div className={styles.desktop__titles}>
						<BlockTitle color="black">Set up in under 90 seconds!</BlockTitle>
						<p className={styles.block__subtitle}>
							Sign up today and receive 60 days free of charge.
						</p>
					</div>
					<AddLicense />
				</div>
			</div>
		</div>
	)
}

export default GetStarted
