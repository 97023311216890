import { TPolicy } from 'src/graphql/types'
import { UPDATE_CHILD } from 'src/graphql/mutation'
import { useMutation } from '@apollo/client'
import CustomSelect from '../../CustomSelect'
import styles from '../DashboardChild.module.scss'

type Props = {
	policy: TPolicy
	list: [{ value: string; label: string }]
	childId: string
	refetch: any
}
const ChildNamePolicy: React.FC<Props> = ({ policy, list, childId, refetch }) => {
	const defaultValue = {
		value: policy.id,
		label: policy.name
	}
	const [updateChild] = useMutation(UPDATE_CHILD, {
		onCompleted: async (data) => {
			if (data.updateChild.ok) {
				await refetch()
			}
		}
	})

	const onChange = (_, val) => {
		updateChild({
			variables: {
				childId,
				policyId: val.value
			}
		})
	}
	return (
		<div className={styles.field}>
			<CustomSelect
				defaultValue={defaultValue}
				name="policy"
				onChange={onChange}
				list={list}
				label="Policy"
				className={styles.field__input}
			/>
			<span className={styles.field__disclaimer}>
				Select a different policy, if necessary.
			</span>
		</div>
	)
}

export default ChildNamePolicy
