import BlockTitle from 'src/components/elements/BlockTitle'
import styles from './HeroBlock.module.scss'

const HeroBlock = () => {
	return (
		<div className={styles.hero}>
			<div className={styles.hero__school}>
				<img src="/images/cotswold-logo.jpg" alt="" />
				<span>Cotswold Guardians</span>
			</div>
			<div className={styles.hero__container}>
				<BlockTitle color="white" classname={styles.hero__title}>
					Cotswold Guardians are pleased to be able to introduce a new initiative that
					will provide digital safeguarding for all our students
				</BlockTitle>
				<p className={styles.hero__subtitle}>
					We believe that all devices used by children should have adequate protection, so
					that the risks to young people are minimised.
				</p>
				<p className={styles.hero__subtitle}>
					As an accredited guardianship we have a duty of care, to make sure that children
					have adequate protection whilst using technology.
				</p>
				<p className={styles.hero__subtitle}>
					This is particularly important when they are away from home, in school or with a
					host family.
				</p>
				<p className={styles.hero__subtitle}>
					All schools in the UK have e-safety policies but we know, anecdotally, that a
					large proportion of digital safeguarding problems originate outside the school’s
					network. We feel that by taking this initiative we are helping to protect your
					children from engaging inadvertently in inappropriate digital content whilst
					outside school.
				</p>
				<p className={styles.hero__description}>
					We have chosen SchoolsMobile-Digital Safeguarding, as it provides both threat
					protection and age-appropriate content controls. We believe it is important that
					you install the software on all their devices and be able to monitor any
					incoming threats
				</p>
			</div>
		</div>
	)
}

export default HeroBlock
